.activeRoute {
  background-color: #552d1e;
  border-radius: 5px;
  transition: 0.5s;
}

.activeRoute p {
  color: #fff !important;
}

.activeRoute svg path {
  fill: #fff !important;
}
